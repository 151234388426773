<template>
  <Layout>
    <div class="container-fluid pt-4">
      <div class="row">
        <div class="col">
          <h5 class="page-header">Create reminder</h5>
        </div>
      </div>
      <hr class="dotted-spacer" />
      <!-- Loading spinner -->
      <div
        v-if="$apollo.loading"
        class="position-fixed end-0 w-100 justify-content-center"
        style="background: rgba(255, 255, 255, 0.8); height: 100vh; z-index: 1050"
      >
        <div class="text-center col pt-5 mt-5">
          <BaseSpinner />
          <p class="d-block lead fs-4 mt-5">Loading data</p>
        </div>
      </div>
      <div v-else class="row px-5 pt-4">
        <div class="col col-md-8 col-lg-6 col-xl-4">
          <ValidationObserver ref="form" v-slot="{}">
            <form @submit.prevent="createReminder">
              <h5>Reminder</h5>

              <BaseAlert v-if="alert.type" class="mb-4" :type="alert.type">
                <span slot="title">{{ alert.title }}</span>
                <ul v-if="typeof alert.message === 'object'" slot="message">
                  <li v-for="m in alert.message" :key="m">{{ m }}</li>
                </ul>
                <span v-else slot="message">{{ alert.message }}</span>
              </BaseAlert>

              <BaseFormGroup :label="'Reminder text'">
                <ValidationProvider v-slot="{ errors }" name="Reminder text" rules="required">
                  <BaseInput v-model="reminder.text" type="text" name="text" :error="errors[0]" />
                </ValidationProvider>
              </BaseFormGroup>

              <BaseFormGroup :label="'Date(s)'">
                <BaseDatepicker v-model="reminder.dates" name="dates" />
              </BaseFormGroup>

              <BaseFormGroup :label="'Blocking'">
                <BaseInput v-model="reminder.blocking" type="checkbox" name="blocking" />
              </BaseFormGroup>

              <BaseFormGroup class="mt-5 mx-5">
                <BaseButton type="submit" :disabled="$apollo.loading" :classes="'btn-success'">
                  <span>Save</span>
                </BaseButton>

                <BaseButton
                  class="btn-secondary mx-5 px-4"
                  :disabled="$apollo.loading"
                  type="button"
                  @click="$router.push({ name: 'reminders' })"
                >
                  <span>Back</span>
                </BaseButton>
              </BaseFormGroup>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@layouts/main";
import { LocalGetSelf } from "@gql/user";
import { CreateReminder } from "@gql/reminder";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import { format, parseISO } from "date-fns";
// import { find } from "lodash";

export default {
  page() {
    return {
      title: "Create reminder",
      meta: [
        {
          name: "description",
          content: `Create reminder.`,
        },
      ],
    };
  },
  components: { Layout, ValidationProvider, ValidationObserver },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      alert: {},
      reminder: {
        dates: {
          startDate: format(new Date(), "yyyy-MM-dd"),
          endDate: format(new Date(), "yyyy-MM-dd"),
        },
        text: "",
        blocking: false,
      },
    };
  },
  apollo: {
    user: {
      query: LocalGetSelf,
      update(data) {
        if (!data.user.isAdminUser) {
          this.$router.push({ name: "403", params: { resource: "user.edit" } });
        }
        return data.user;
      },
    },
  },
  methods: {
    async createReminder() {
      this.isLoading = true;
      this.alert = {};

      // Validate the form
      if (!(await this.$refs.form.validate())) {
        this.isLoading = false;
        this.alert.type = "error";
        this.alert.title = "Error";
        this.alert.message = "Please check the form below for errors";
        return false;
      }
      // Validation good, reset
      this.$refs.form.reset();

      this.$apollo
        .mutate({
          mutation: CreateReminder,
          variables: {
            id: this.id,
            text: this.reminder.text,
            startDate: new Date(
              (" " + format(parseISO(this.reminder.dates.startDate), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")).slice(1)
            ),
            endDate: new Date(
              (" " + format(parseISO(this.reminder.dates.endDate), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")).slice(1)
            ),
            blocking: this.reminder.blocking,
            companyId: this.user.company.id,
          },
          error(err) {
            this.$log.error(err);
            this.alert.type = "error";
            this.alert.title = "There was a problem";
            this.alert.message = err;
          },
        })
        .then((data) => {
          this.alert.type = "success";
          this.alert.title = "Success";
          this.alert.message = "Reminder updated";
          this.isLoading = false;
          this.$refs.form.reset();
          // Redirect
          setTimeout(() => {
            this.$router.push({ name: "reminders" });
          }, 1000);
        })
        .catch((err) => {
          // Error
          this.alert.type = "error";
          this.alert.title = "There was a problem";
          this.alert.message = err;
        });
    },
  },
};
</script>
